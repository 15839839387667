import React from 'react'

const IconNotificationFallbackImage = () => {
	return (
		<svg
			width="20"
			height="19"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.13191 18.0578V15.7269C3.13191 15.0663 2.88599 14.5891 2.1328 14.5972C1.37153 14.6053 1.11018 15.0825 1.11018 15.6953V18.0578H0.119141V13.7937H0.999334L1.03898 14.2628C1.29298 13.9763 1.80024 13.7061 2.34715 13.7061C3.52907 13.7061 4.13911 14.6053 4.13911 15.735V18.0578H3.13191Z"
				fill="#777777"
			/>
			<path
				d="M5.9633 16.2908C6.00295 16.9588 6.439 17.2932 7.0975 17.2932C7.61284 17.2932 8.0489 17.0943 8.31098 16.7121L9.11996 17.1576C8.58847 17.9214 7.94612 18.1762 7.06593 18.1762C5.89209 18.1762 4.93262 17.3565 4.93262 15.9646C4.93262 14.5726 5.89209 13.7051 7.06593 13.7051C8.09662 13.7051 8.96139 14.2699 9.17502 15.4233C9.21466 15.6538 9.19851 16.0839 9.19851 16.2901H5.9633V16.2908ZM8.19939 15.5116C8.12818 14.9306 7.64441 14.5969 7.0652 14.5969C6.51829 14.5969 6.03451 14.9313 5.96257 15.5116H8.19866H8.19939Z"
				fill="#777777"
			/>
			<path
				d="M14.0525 18.0581L13.1246 14.8604L12.2128 18.0581H10.9281L9.5957 13.7939H10.666L11.5859 17.1117L12.5373 13.7939H13.7192L14.6706 17.1117L15.5904 13.7939H16.6211L15.2968 18.0581H14.0517H14.0525Z"
				fill="#777777"
			/>
			<path
				d="M19.271 14.8593C19.0493 14.6288 18.7873 14.4852 18.5017 14.4852C18.1926 14.4852 17.9863 14.6678 17.9863 14.8829C17.9863 15.1775 18.2242 15.2887 18.6052 15.4477C19.4457 15.7976 20.0007 16.0922 20.0007 16.8559C20.0007 17.6829 19.2556 18.1764 18.3754 18.1764C17.7962 18.1764 17.2809 17.9937 16.916 17.54L17.5584 16.9671C17.7404 17.1821 18.0979 17.3567 18.3754 17.3567C18.6529 17.3567 18.97 17.2057 18.97 16.9192C18.97 16.6327 18.6845 16.4898 18.106 16.2667C17.4321 16.0045 16.988 15.6223 16.988 14.9617C16.988 14.1899 17.6068 13.6729 18.5024 13.6729C19.0816 13.6729 19.5015 13.8879 19.9141 14.3092L19.2718 14.8578L19.271 14.8593Z"
				fill="#777777"
			/>
			<path
				d="M4.16797 6.89124C4.16797 9.19713 6.03774 11.0714 8.33549 11.0714V8.68381C7.34959 8.68381 6.54941 7.88032 6.54941 6.89124V5.24892H8.33549V2.85908H6.54941V0.824219L4.16797 2.85908V6.89124Z"
				fill="url(#paint0_linear_7054_95605)"
			/>
			<path
				d="M19.9753 8.42372C19.6802 8.59385 19.4078 8.68443 19.0496 8.68443C18.0637 8.68443 17.2628 7.88168 17.2628 6.89113C17.2628 5.90058 18.0637 5.10004 19.0496 5.10004C19.4078 5.10004 19.6802 5.19063 19.9753 5.35928V2.81331C19.678 2.74629 19.3682 2.71094 19.0496 2.71094C16.7489 2.71094 14.8828 4.58157 14.8828 6.89113C14.8828 9.2007 16.7489 11.0713 19.0496 11.0713C19.3682 11.0713 19.678 11.036 19.9753 10.969V8.42372Z"
				fill="url(#paint1_linear_7054_95605)"
			/>
			<path
				d="M14.8832 6.891C14.8832 5.91592 15.2157 5.02111 15.7744 4.30968V4.30674C15.0095 3.33534 13.8283 2.71081 12.5032 2.71081C11.8631 2.71081 11.2582 2.85516 10.7164 3.11513V0.823242H8.33496L8.33569 6.891C8.33569 9.19689 10.204 11.0712 12.5032 11.0712C13.8283 11.0712 15.0095 10.4467 15.7744 9.47453C15.2157 8.76311 14.8832 7.86682 14.8832 6.891ZM12.5032 8.68357C11.5173 8.68357 10.7164 7.87934 10.7164 6.891C10.7164 5.90266 11.518 5.09991 12.5032 5.09991C13.4884 5.09991 14.2864 5.90487 14.2864 6.891C14.2864 7.87714 13.4877 8.68357 12.5032 8.68357Z"
				fill="url(#paint2_linear_7054_95605)"
			/>
			<path
				d="M1.78682 6.89056C1.78682 7.8789 0.985905 8.68313 0 8.68313V11.0708C2.29849 11.0708 4.16752 9.19644 4.16752 6.89056V2.85987L1.78608 2.8584V6.89056H1.78682Z"
				fill="url(#paint3_linear_7054_95605)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_7054_95605"
					x1="4.16797"
					y1="5.94783"
					x2="8.33549"
					y2="5.94783"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.4" stopColor="#777777" />
					<stop offset="0.65" stopColor="#777777" stopOpacity="0.85" />
					<stop offset="1" stopColor="#777777" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_7054_95605"
					x1="14.8835"
					y1="6.89113"
					x2="19.9753"
					y2="6.89113"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.4" stopColor="#777777" />
					<stop offset="0.65" stopColor="#777777" stopOpacity="0.85" />
					<stop offset="1" stopColor="#777777" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_7054_95605"
					x1="8.33496"
					y1="5.94759"
					x2="15.7744"
					y2="5.94759"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.4" stopColor="#777777" />
					<stop offset="0.65" stopColor="#777777" stopOpacity="0.85" />
					<stop offset="1" stopColor="#777777" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_7054_95605"
					x1="0"
					y1="6.96495"
					x2="4.16826"
					y2="6.96495"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.4" stopColor="#777777" />
					<stop offset="0.65" stopColor="#777777" stopOpacity="0.85" />
					<stop offset="1" stopColor="#777777" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default IconNotificationFallbackImage
