import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import Box from '@mui/joy/Box'
import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import LiveReserveButton from '@components/button/LiveReserveButton'
import UIImage from '@components/ui/UIImage'
import { NewsScheduleStatus } from '@page/live/_component/LiveBody/NewsScheduleSection/NewsScheduleItem'
import UIButton from '@components/ui/UIButton'
import { IconMidDot } from 'src/assets/icons/IconMiddot'

interface ScheduleItemProps extends Omit<UIFlexProps, 'ref'> {
	idx: number
	src: string
	alt?: string
	title: string
	time?: string
	isReserved?: boolean
	status: NewsScheduleStatus
	liveTagTypeCode?: string
}
const ScheduleItem = ({
	idx,
	src,
	alt,
	title,
	time,
	isReserved,
	status,
	liveTagTypeCode,
	...props
}: ScheduleItemProps) => {
	const getLiveTagText = (code?: string) => {
		switch (code) {
			case 'ONAIR':
				return 'ON AIR'
			case 'ORIGINAL':
				return '오리지널'
			case 'SITE_LIVE':
				return '현장 LIVE'
			case 'ISSUE':
				return '이슈'
			case 'LIVE':
				return 'LIVE'
			default:
				return code
		}
	}

	return (
		<Box {...props}>
			<UIFlex
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				padding={{ xs: pxToRem(20), lg: `${pxToRem(20)} ${pxToRem(24)}` }}
				sx={{
					background: status === 'live' ? color.colDarkBlue : color.colWhite,
					'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
					'& .MuiButton-root': {
						flex: 'none',
					},
				}}
				borderRadius={pxToRem(8)}
				overflow="hidden"
			>
				<UIFlex
					flexGrow="1"
					flexDirection="row"
					sx={{
						'&> *:not(:first-child)': { marginLeft: pxToRem(12) },
					}}
					alignItems="center"
				>
					<Box width={pxToRem(48)} borderRadius="9999px" overflow="hidden" flexShrink="0">
						<UIImage src={src} alt={alt ?? ''} ratio={1} />
					</Box>
					<UIFlex
						sx={{
							'&> *:not(:first-child)': { marginTop: pxToRem(4) },
						}}
					>
						<UIText
							fontSize={{ xs: pxToRem(17), lg: pxToRem(18) }}
							lineHeight={pxToRem(24)}
							fontWeight={700}
							color={status === 'live' ? color.colWhite : color.colBlack}
							lineClamp={1}
						>
							{title}
						</UIText>
						<UIText
							level="CaptionTime"
							fontWeight={500}
							lineClamp={1}
							color={status === 'live' ? color.colWhite : color.colGray5}
							sx={{ opacity: status === 'live' ? 0.6 : 1 }}
						>
							{time}
						</UIText>
					</UIFlex>
				</UIFlex>
				{status === 'live' && (
					<UIButton
						disabled
						sx={{
							'&.MuiButton-root': {
								background:
									liveTagTypeCode === 'ONAIR'
										? color.colExclusiveRed
										: color.colWhite,
								color:
									liveTagTypeCode === 'ONAIR' ? color.colWhite : color.colBlack,
							},
						}}
					>
						<IconMidDot
							color={
								liveTagTypeCode === 'ONAIR' ? color.colWhite : color.colExclusiveRed
							}
						/>
						<UIText
							color={liveTagTypeCode === 'ONAIR' ? color.colWhite : color.colBlack}
							fontSize={pxToRem(14)}
							fontWeight={600}
							lineHeight={pxToRem(16)}
						>
							{getLiveTagText(liveTagTypeCode)}
						</UIText>
					</UIButton>
				)}
				{status === 'wait' && (
					<LiveReserveButton isReserved={isReserved} idx={idx} title={title} />
				)}
				{status === 'ended' && (
					<UIButton
						disabled
						// 이 화면에서만 쓰이는 disabled 버튼 배색으로 보임
						sx={{
							'&.MuiButton-root': {
								color: color.colGray5,
								background: color.colGray1,
							},
						}}
					>
						방송 종료
					</UIButton>
				)}
			</UIFlex>
		</Box>
	)
}

export default ScheduleItem
