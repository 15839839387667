import { LiveScheduleResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Box from '@mui/joy/Box'
import Carousel from '@components/common/Carousel/Carousel'
import { DATE_FORMAT, dayjs } from '@utils/date'
import ScheduleItem from './ScheduleItem'
import { NewsScheduleStatus } from '@page/live/_component/LiveBody/NewsScheduleSection/NewsScheduleItem'
import { isMobile } from 'react-device-detect'
import UILink from '@components/ui/UILink'

// TODO : Carousel 의 NavButton 수정 필요
const ListSchedule = ({
	list = [],
	areaIdx,
}: {
	list?: Array<LiveScheduleResponseVo>
	areaIdx?: number
}) => {
	const currentTime = Number(dayjs().format(DATE_FORMAT.FULL_AS_NUMBER))
	const getDateTime = (time: string) => {
		const current = dayjs().format(DATE_FORMAT.DATE_AS_NUMBER)
		const schedule = dayjs(time)
		const calcDate = Number(schedule.format(DATE_FORMAT.DATE_AS_NUMBER)) - Number(current)
		if (calcDate === 0) {
			return schedule.format(DATE_FORMAT.TIME_AS_A)
		}
		if (calcDate === 1) {
			return `익일 ${schedule.format(DATE_FORMAT.TIME_AS_A)}`
		}

		return schedule.format(DATE_FORMAT.FULL)
	}
	const getScheduleStatus = (item: LiveScheduleResponseVo) => {
		const startDate = dayjs(item.liveScheduleStartDate).add(-15, 'minute')
		const startDatetime = Number(startDate.format(DATE_FORMAT.FULL_AS_NUMBER))
		const endDate = dayjs(item.liveScheduleEndDate)
		const endDatetime = Number(endDate.format(DATE_FORMAT.FULL_AS_NUMBER))
		let status = 'ended'
		if (startDatetime < currentTime && currentTime < endDatetime) {
			status = 'live'
		}
		if (currentTime < startDatetime) {
			status = 'wait'
		}
		return status as NewsScheduleStatus
	}
	const drawScheduleItem = (item: LiveScheduleResponseVo) => {
		const status = getScheduleStatus(item)
		return status === 'live' ? (
			<UILink
				href={`/live/${item.liveScheduleIdx}`}
				display="block"
				key={`live-${item.programIdx}`}
			>
				<ScheduleItem
					idx={item.liveScheduleIdx}
					src={item.programIconImgUrl}
					title={item.programName}
					time={getDateTime(item.liveScheduleStartDate)}
					isReserved={item.isLiveReservation ?? false}
					status={status}
					liveTagTypeCode={item.liveTagTypeCode}
					px={{ xs: pxToRem(20), md: 0 }}
				/>
			</UILink>
		) : (
			<ScheduleItem
				key={`schedule-${item.programIdx}-${areaIdx}`}
				idx={item.liveScheduleIdx}
				src={item.programIconImgUrl}
				title={item.programName}
				time={getDateTime(item.liveScheduleStartDate)}
				isReserved={item.isLiveReservation ?? false}
				status={status}
				liveTagTypeCode={item.liveTagTypeCode}
				px={{ xs: pxToRem(20), md: 0 }}
			/>
		)
	}
	const getInitialIndex = (item: LiveScheduleResponseVo) =>
		getScheduleStatus(item) === 'live' || getScheduleStatus(item) === 'wait'
	const initialSlide = isMobile ? list.findIndex(getInitialIndex) : 0
	return (
		<Box
			mx={{ xs: pxToRem(-20), md: 0 }}
			sx={(theme) => ({
				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.up('md')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(16 * 1)}) / 2)`,
						},
					},
					[theme.breakpoints.up('lg')]: {
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(16 * 2)}) / 3)`,
						},
					},
				},
				'&': {
					'.swiper .swiper-pagination-bullets.swiper-pagination-horizontal': {
						padding: `0 ${pxToRem(20)}`,
					},
					[theme.breakpoints.up('md')]: {
						'.swiper .swiper-pagination-bullets.swiper-pagination-horizontal': {
							display: 'none',
						},
						'.swiper-button-prev, .swiper-button-next': {
							top: `calc(50% - ${pxToRem(18)})`,
						},
					},
				},
			})}
		>
			<Carousel
				navigation
				navSize="md"
				slidesPerView={1}
				pagination
				spaceBetween={16}
				initialSlide={initialSlide === -1 ? 0 : initialSlide}
				a11y={{
					enabled: false,
				}}
				breakpoints={{
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				}}
			>
				{list.map(drawScheduleItem)}
			</Carousel>
		</Box>
	)
}

export default ListSchedule
