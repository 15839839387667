import { ArticleListResponseVo } from '@schemas/non-auth'
import { pxToRem } from '@ui/style/muiTheme'
import Box from '@mui/joy/Box'
import Carousel from '@components/common/Carousel/Carousel'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { getRelativeTime } from '@utils/date'
import { ParsedUrlQueryInput } from 'querystring'
import { getAccessListParams, getArticleParams } from '@utils/getLink'
import { imgDefaultV } from 'src/shared/variables'

const ListShorts = ({
	list = [],
	isDark,
	isTopic = false,
	query,
	areaIdx,
}: {
	list?: Array<ArticleListResponseVo>
	isDark?: boolean
	isTopic?: boolean
	query?: ParsedUrlQueryInput
	areaIdx?: number | string
}) => {
	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			layoutGap: { xs: pxToRem(12), md: pxToRem(16) },
		},
		thumbnailConfig: {
			radius: pxToRem(8),
			thumbRatio: '9/16',
			badgeInset: pxToRem(6),
			thumbWidth: {
				xs: pxToRem(220),
				md: pxToRem(isTopic ? 162 : 224),
				lg: pxToRem(214),
				xl: pxToRem(224),
			},
			thumbHeight: {
				xs: pxToRem(390),
				md: pxToRem(isTopic ? 288 : 398),
				lg: pxToRem(380),
				xl: pxToRem(398),
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: isTopic
						? {
								xs: pxToRem(16),
								md: pxToRem(15),
								lg: pxToRem(16),
						  }
						: pxToRem(16),
					lineHeight: {
						xs: pxToRem(24),
						md: pxToRem(22),
					},
				},
				'.info-additionalText': {
					lineHeight: {
						xs: pxToRem(16),
						md: pxToRem(18),
					},
				},
			},
		},
	}
	const carouselConfig = {
		slidesPerView: 'auto' as const,
		spaceBetween: 16,
		slidesOffsetBefore: 20,
		slidesOffsetAfter: 20,
		breakpoints: {
			768: {
				slidesPerView: isTopic ? 4 : 3,
				spaceBetween: 24,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 24,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
			1280: {
				slidesPerView: 5,
				spaceBetween: 24,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
			},
		},
		navPositionTop: '41%',
	}
	const drawShortItem = (item: ArticleListResponseVo) => (
		<ContentCard
			key={`shorts-${item.articleIdx}-${areaIdx}`}
			content={{
				articleIdx: item?.articleIdx,
				title: item.articleTitle,
				href: `/shorts/${item.articleIdx}?${
					isTopic
						? getAccessListParams('TOPIC', `${areaIdx}`)
						: getArticleParams('HOME', areaIdx)
				}`,
				query,
				src: item.vodInfo
					? item.vodInfo?.vodThumbnailImgUrl
					: item?.articleThumbnailImgUrl ?? imgDefaultV,
				alt: '',
				additionalText: getRelativeTime(item?.publicationDate ?? ''),
				duration: item?.vodInfo?.playTime,
			}}
			layoutConfig={cardProps.layoutConfig}
			thumbnailConfig={{
				...cardProps.thumbnailConfig,
				isContain: item.articleThumbnailImageViewType !== 'WIDTH',
			}}
			infoConfig={cardProps.infoConfig}
		/>
	)
	return (
		<Box
			mx={{ xs: pxToRem(-20), md: 0 }}
			sx={(theme) => ({
				'& .swiper:not(.swiper-initialized)': {
					[theme.breakpoints.down('md')]: {
						'& .swiper-wrapper': {
							paddingLeft: theme.pxToRem(20),
							paddingRight: theme.pxToRem(20),
							'&> *:not(:first-child)': { marginLeft: pxToRem(16) },
						},
					},
					[theme.breakpoints.up('md')]: {
						'& .swiper-wrapper': {
							'&> *:not(:first-child)': { marginLeft: pxToRem(24) },
						},
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(24 * 2)}) / ${isTopic ? 4 : 3})`,
						},
					},
					[theme.breakpoints.up('lg')]: {
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(24 * 3)}) / 4)`,
						},
					},
					[theme.breakpoints.up('xl')]: {
						'& .swiper-slide': {
							width: `calc((100% - ${theme.pxToRem(24 * 4)}) / 5)`,
						},
					},
				},
				'& .swiper-slide': {
					[theme.breakpoints.down('md')]: {
						width: `${pxToRem(220)}`,
					},
				},
			})}
		>
			<Carousel {...carouselConfig}>{list?.map(drawShortItem)}</Carousel>
		</Box>
	)
}

export default ListShorts
